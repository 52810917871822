import {
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import { FC } from "react";
import { Field, useFormikContext } from "formik";
import { Select } from "formik-mui";
import { useRoomTypesSubscription } from "../__generated__/types";
import { useSelectedPropertyContext } from "./SelectedPropertyProvider";
import React from "react";
import { translations } from "../translations";

export const RoomTypesSelect: FC = () => {
  const { selected, selected_locale } = useSelectedPropertyContext();
  const { data, loading } = useRoomTypesSubscription({
    variables: { property_id: selected },
  });
  return (
    <Grid item xs={12}>
      <FormControl fullWidth>
        <Field
          name="room_type_id"
          component={Select}
          input={
            <OutlinedInput label={translations[selected_locale].room_type} />
          }
          InputLabelProps={{ shrink: false }}
          label={translations[selected_locale].room_type}
          disabled={loading || data?.room_type.length === 0}
        >
          {data?.room_type.map(({ id, room_type }) => (
            <MenuItem value={id}>{room_type}</MenuItem>
          ))}
        </Field>
      </FormControl>
    </Grid>
  );
};
