import { Container, Paper } from "@mui/material";
import { navigate } from "gatsby";
import { useSnackbar } from "notistack";
import React from "react";
import { RoomForm } from "../../components/forms/RoomForm";
import { useSelectedPropertyContext } from "../../components/SelectedPropertyProvider";
import { translations } from "../../translations";
import {
  useDeleteRoomByIdMutation,
  useGetRoomByIdSubscription,
  useUpdateRoomByIdMutation,
} from "../../__generated__/types";

const RoomPageById = ({ params }) => {
  const { selected_locale } = useSelectedPropertyContext();
  const { data, loading, error } = useGetRoomByIdSubscription({
    variables: { id: params.id },
  });
  const [updateRoom] = useUpdateRoomByIdMutation();
  const [deleteRoom] = useDeleteRoomByIdMutation();
  const { enqueueSnackbar } = useSnackbar();
  if (loading) return null;
  if (error) return <div>{error.message}</div>;
  return (
    <Container sx={{ mt: 3, mb: 2 }}>
      <Paper sx={{ p: 2 }}>
        <RoomForm
          initialValues={data?.room_by_pk}
          onSubmit={async (values) => {
            await updateRoom({ variables: { id: params.id, _set: values } });
            enqueueSnackbar(
              `${translations[selected_locale].room_number} '${values.room_number}' ${translations[selected_locale].is_succesfully_updated}`,
              {
                variant: "success",
              }
            );
          }}
          onDelete={async () => {
            await deleteRoom({
              variables: { id: params.id },
            });
            enqueueSnackbar(
              `${translations[selected_locale].room_number} '${data?.room_by_pk?.room_number}' ${translations[selected_locale].is_succesfully_deleted}`,
              {
                variant: "success",
              }
            );
            navigate(-1);
          }}
        />
      </Paper>
    </Container>
  );
};

export default RoomPageById;
